<template>
  <v-container fluid class="m-0 p-0">
    <v-row dense class="mt-1" v-if="uc != null">
      <v-col cols="12" class="">
        <v-card class="pb-1" v-bg:B>
          <v-card class="m-0 p-0" flat :color="hexTints(area.cor,0.95)">
            <v-container fluid class="">
              <v-row dense align="center" justify="start" class="">
                <v-col cols="auto" class="m-0 p-0">
                  <v-avatar dark :color="hexTints(area.cor,0.8)" size="32" class="m-0 p-0">
                    <i v-i:duo#note#22 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
                  </v-avatar>
                </v-col>
                <v-col cols="10" class="m-0 p-0 ps-2">
                  <div class="mt-0 line-height-1 f-lato fw-600 fs-10pt text-truncate">
                    <span>Conteúdos</span>
                    <span class="ms-1 round border pe-1" :style="'background-color:'+hexTints(area.cor,0.87)">
                      {{qtdeConteudos}}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <div class="mx-1">
            <v-alert type="info" v-c:P :color="hexTints(area.cor,0.85)" class="m-0 p-2 mt-2 fs-10pt" v-if="qtdeConteudos == 0">
              Nenhum conteúdo cadastrado.
            </v-alert>
            <div class="" v-if="qtdeConteudos > 0">
              <conteudoitem @moveDown="moveDown" @moveUp="moveUp" :down="index==qtdeConteudos-1" :up="index==0" @excluir="excluirItem" @editar="editarItem" :area="area" :uc="uc" :item="item" v-for="(item,key,index) in conteudos" :key="key+refresh+index" />
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <promptdialogexcluir ref="promptdialogexcluirref" @additem="excluir"/>
    <promptdialogupdate :area="area" ref="promptdialogupdateref" @edititem="editar"/>
  </v-container>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import ementaModel from "./ementa-model.js";

export default {
  name: "conteudosview",
  components: {
    'conteudoitem': () => import('./conteudo-item.vue'),
    'promptdialogexcluir': () => import('./promptdialogExcluir.vue'),
    'promptdialogupdate': () => import('./promptdialogupdate.vue'),
  },
  props: {
    area: { default: null, type: Object },
    uc: { default: null, type: Object }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      ementa: {},
      conteudos: {},
      qtdeConteudos: 0,
      ordem: "",
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.build();
  },
  methods:{

    build() {
      var self = this;
      rdb.ref('/curriculo/conteudos/ucs/'+this.uc.id).on('value',function(snapshot) {
        var linha = snapshot.val();
        if(linha == null) {
          self.conteudos = {};
          self.qtdeConteudos = 0;
          self.ordem = "";
        } else {
          self.qtdeConteudos = linha.qtde;
          self.ordem = linha.ordem;
          self.conteudos = {};
          var vetOrdem = [];
          if(linha.ordem != undefined) {
            vetOrdem = linha.ordem.split(";");
          }
          for(var idx in vetOrdem) {
            self.conteudos[vetOrdem[idx]] = linha.conteudos[vetOrdem[idx]];
          }
        }
        self.refresh++;
        console.log("self.ementa",self.conteudos);
      });
    },

    excluir(item) {
      console.log("excluir",item);
      ementaModel.remove(item,this.uc);
    },

    excluirItem(item) {
      console.log("excluirItem",item);
      this.$refs.promptdialogexcluirref.show(item);
    },

    editar(item) {
      console.log("editar",item);
      ementaModel.editarTexto(item,this.uc);
    },

    editarItem(item) {
      console.log("editarItem",item);
      this.$refs.promptdialogupdateref.show(item);
    },

    moveDown(item) {
      var self = this;
      console.log("moveDown",item);
      var vetOrdem = self.ordem.split(";");
      var pos1 = vetOrdem.lastIndexOf(item.id);
      var aux = vetOrdem[pos1];
      vetOrdem[pos1] = vetOrdem[pos1+1];
      vetOrdem[pos1+1] = aux;
      var newOrdem = vetOrdem.join(";");
      console.log("newOrdem",newOrdem);
      rdb.ref("/curriculo/conteudos/ucs/"+this.uc.id+"/ordem").set(newOrdem);
    },

    moveUp(item) {
      console.log("moveUp",item);
      var self = this;
      var vetOrdem = self.ordem.split(";");
      var pos1 = vetOrdem.lastIndexOf(item.id);
      var aux = vetOrdem[pos1];
      vetOrdem[pos1] = vetOrdem[pos1-1];
      vetOrdem[pos1-1] = aux;
      var newOrdem = vetOrdem.join(";");
      console.log("newOrdem",newOrdem);
      rdb.ref("/curriculo/conteudos/ucs/"+this.uc.id+"/ordem").set(newOrdem);
    }

  }
}
</script>

<style>

.v-alert .v-alert__icon.v-icon {
  color: #808080;
}
</style>
